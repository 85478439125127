<template>
  <!-- <transition name="slideUp"> -->
  <div v-if="showStatus" class="message-gift position-relative">
    <div
      class="message-gift__header d-flex align-items-center justify-content-between"
    >
      <button class="btn btn-close" @click="changeShowGiftShop('popup')">
        <img :src="require('@/assets/image/left-arrow.svg')" alt="back" />
      </button>
      <p class="mb-0 f-w6 fs-14">ギフトストア</p>
      <div></div>
    </div>
    <div class="message-gift__body d-flex flex-wrap justify-content-left">
      <!-- <template v-if="gifts.length > 4">
          <div
            class="position-absolute icon-slider icon-slider-next"
            @click="next()"
            v-if="isShowNext"
          >
            <img src="@/assets/image/message/next.svg" alt="" />
          </div>
          <div
            v-if="isShowPre"
            class="position-absolute icon-slider icon-slider-pre"
            @click="pre()"
          >
            <img src="@/assets/image/message/pre.svg" alt="" />
          </div>
        </template> -->
      <GiftBox
        v-for="(gift, index) in gifts"
        :key="index"
        :data="gift"
        @load-success="totalLoad++"
        @close-popup="$emit('show-gift-shop')"
        @change-confirm="onClickGift(gift)"
      />
    </div>
    <MessageGiftBoxConfirm
      :showModal="openConfirm"
      :imageUrl="imageUrl"
      @change-show-confirm="openConfirm = false"
      @purchase-gift="purchaseGift"
    />
  </div>
  <!-- </transition> -->
</template>

<script>
import MessageGiftBoxConfirm from "./MessageGiftBoxConfirm.vue";
import GiftBox from "./GiftBox.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    GiftBox,
    MessageGiftBoxConfirm
  },
  name: "MessageGiftShop",
  props: {
    showStatus: {
      type: Boolean
    },
    toUserId: {
      type: String
    },
    roomId: {
      type: String
    }
  },
  data() {
    return {
      openConfirm: false,
      giftId: "",
      totalLoad: 0,
      imageUrl: "",
      isShowNext: true,
      isShowPre: false
    };
  },
  methods: {
    changeShowGiftShop(type) {
      this.$emit("show-gift-shop", type);
    },
    onClickGift(data) {
      this.openConfirm = true;
      this.giftId = data.gift_id;
      this.imageUrl = data.image_url;
    },
    purchaseGift() {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("gift/purchaseGift", {
          user_id: this.user.user_id,
          gift_id: this.giftId
        })
        .then(response => {
          this.openConfirm = false;
          this.tipping(response?.data?.user_gift_id);
        })
        .catch(() => {
          this.openConfirm = false;
          this.$root.$refs.loading.finish();
        });
    },
    tipping(userGiftId) {
      this.$store
        .dispatch("gift/tip", {
          user_id: this.user.user_id,
          user_gift_id: userGiftId,
          to_user_id: this.toUserId,
          room_id: this.roomId
        })
        .then(() => {
          this.changeShowGiftShop("tip");
          this.$emit("close-popup");
          this.$emit("scroll-bottom");
          this.$root.$refs.loading.finish();
        })
        .catch(() => {
          this.changeShowGiftShop();
          this.$emit("close-popup");
          this.$emit("scroll-bottom");
          this.$root.$refs.loading.finish();
        });
    }
    // pre() {
    //   let body = document.getElementById("gift-shop-body");
    //   body.scrollLeft -= 150;
    //   this.isShowNext = true;
    //   if (body.scrollLeft - 150 <= 0) {
    //     this.isShowPre = false;
    //   }
    // },
    // next() {
    //   let body = document.getElementById("gift-shop-body");
    //   body.scrollLeft += 150;
    //   this.isShowPre = true;
    //   if (body.scrollLeft + body.clientWidth + 150 >= body.scrollWidth) {
    //     this.isShowNext = false;
    //   }
    // }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      gifts: "gift/gifts"
    })
  },
  watch: {
    totalLoad(val) {
      if (val === this.gifts.length) {
        this.$emit("updated-success");
        this.totalLoad = 0;
      }
    }
  },
  created() {
    this.$store.dispatch("gift/getGifts");
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1080px) {
  .message-gift {
    height: 310px;
  }
}

.message-gift {
  .message-gift__body {
    max-height: 465px;
    height: calc(100% - 50px);
    overflow-y: auto;
  }
  border-radius: 20px;
  box-shadow: 0 -2px 15px #00000029;
  // position: fixed;
  // position: absolute;
  // right: 0;
  // bottom: 0;
  // left: 0;
  width: 100%;
  z-index: 2;
  background-color: #ffffff;

  &__header {
    height: 50px;
    border-bottom: 2px solid #e1e1e1;
    padding: 0 20px;

    p {
      color: #000000;
    }
    .btn {
      width: 20px;
      padding: 0;
      img {
        display: block;
      }
      &.btn-close {
        img {
          width: 14px;
          opacity: 0.7;
        }
      }
      &.btn-shop {
        img {
          width: 19px;
          opacity: 0.7;
        }
      }
    }
  }

  &__body {
    padding: 20px 30px 5px;
    // overflow-x: scroll;
    // padding: 20px 0;
    // margin: 0 15px;
  }
}

@media screen and (min-width: 1200px) {
  .message-gift {
    &__body {
      padding: 20px 45px 5px;
      // padding: 25px 0;
      // margin: 0 35px;
      // &::-webkit-scrollbar {
      //   display: none;
      // }
      // -ms-overflow-style: none; /* IE and Edge */
      // scrollbar-width: none; /* Firefox */
      // scroll-behavior: smooth;
      // .icon-slider-next {
      //   top: 50%;
      //   transform: translateY(calc(-50% + 25px));
      //   right: 0;
      // }
      // .icon-slider-pre {
      //   top: 50%;
      //   transform: translateY(calc(-50% + 25px));
      //   left: 0;
      // }
    }
  }
}

// @media screen and (max-width: 1199px) {
//   .icon-slider {
//     display: none;
//   }
// }

.slideUp {
  &-enter,
  &-leave-to {
    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);
  }

  // &-enter-active,
  // &-leave-active {
  //   transition: ease 0.5s;
  // }

  &-enter-to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
</style>
