<template>
  <div class="app-modal" :class="{ active: showModal }">
    <transition name="slide-down">
      <div v-if="showModal" class="app-modal__dialog">
        <div
          class="event-confirm d-flex flex-column align-items-center justify-content-center p-4"
        >
          <button class="btn btn-close" @click="changeShowModal()">
            <img :src="require('@/assets/image/close.svg')" alt="close popup" />
          </button>
          <div class="mt-2">
            <img
              :src="require('@/assets/image/report-user.svg')"
              alt="time report"
            />
          </div>
          <div class="mt-2" v-if="castList.length > 1 && authUser.sex === 1">
            <b-form-group
              class="mb-0 form-check-report"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                v-model="selected"
                :options="castList"
                :aria-describedby="ariaDescribedby"
                name="flavour-2a"
                class="f-w3 check-box"
                stacked
                button-variant="red"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <div class="w-100 mt-2">
            <button
              :disabled="
                castList.length > 1 &&
                  !(
                    selected.length -
                    (userReports.length ? userReports[0].length : 0)
                  ) &&
                  authUser.sex === 1
              "
              @click="sendReport"
              class="btn-confirm"
            >
              運営に報告する
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "ReportConfirm",
  props: {
    showModal: {
      type: Boolean
    },
    userIds: {
      type: Array
    },
    authUser: {
      type: Object
    },
    userReports: {
      type: Array
    }
  },
  data() {
    return {
      selected: [],
      castList: []
    };
  },
  watch: {
    showModal: function() {
      if (
        this.showModal &&
        this.authUser.sex === 1 &&
        this.userReports?.length
      ) {
        this.selected = [...this.userReports[0]];
        this.castList = [];
        (this.userIds || []).forEach(item => {
          if (parseInt(item.id) !== this.authUser.user_id) {
            this.castList.push({
              text: item.nickname,
              value: item.id,
              disabled:
                this.userReports[0].includes(parseInt(item.id)) ||
                this.userReports[1].includes(parseInt(item.id))
            });
          }
        });
      }
    }
  },
  methods: {
    changeShowModal() {
      this.$emit("change-show-confirm", false);
    },
    sendReport() {
      this.$emit("send-report", _.difference(this.selected, this.userReports));
    }
  }
};
</script>

<style lang="scss" scoped>
.app-modal {
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;

  &.active {
    opacity: 1;
    visibility: visible;
  }
  &.default {
    .app-modal__dialog {
      width: 375px;
      max-width: 100%;
    }
  }

  &__close {
    outline: none;
    box-shadow: none;
  }

  &__dialog {
    overflow: auto;
    position: fixed;
    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
    width: 100%;
    max-height: 100vh;
    max-width: 375px;
    background-color: #f5f5f5;
    &__header {
      padding: 9px 0;
      top: 0;
      max-width: 1080px;
      width: 100%;
      background-color: #f5f5f5;
      z-index: 100;
      border-bottom: 1px solid #d9d9d9;
    }
    &--content {
      background-color: #f5f5f5;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1080px;
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
      }
    }
  }

  .app-modal__tilte {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 0;
    color: #000000;
  }

  .app-modal__close {
    position: absolute;
    right: 35px;
    padding: 0;
    img {
      width: 14px;
      display: block;
    }
  }
}

.slide-down {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-20px);
  }

  &-enter-active,
  &-leave-active {
    transition: ease 0.5s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    transform: translateY(0);
  }
}
.modal {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}
.event-confirm {
  width: 100%;
  max-width: 375px;
  background-color: #fff;
  padding: 30px 19px;
  position: relative;

  .btn-close {
    position: absolute;
    top: 15px;
    right: 19px;
    img {
      width: 14px;
      display: block;
    }
  }

  > img {
    width: 105px;
    margin-bottom: 25px;
  }

  p {
    margin-bottom: 30px;
    color: #0b2044;
  }

  .btn-confirm {
    height: 45px;
    border-radius: 5px;
    background-color: #464d77;
    color: #fff;
    width: 100%;
  }
  .btn-confirm:disabled {
    background: #b9b9b9;
  }
  .link {
    color: #1973db;
    text-decoration: underline;
    cursor: pointer;
  }
}
.show {
  &-enter,
  &-leave-to {
    opacity: 0;
    visibility: hidden;
  }

  &-enter-active,
  &-leave-active {
    transition: ease 0.4s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (min-width: 1080px) {
  .app-modal__dialog {
    border-radius: 5px;
  }
}

.custom-control-label {
  padding-top: 3px;
}
</style>
