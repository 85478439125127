<template>
  <div class="create-order f-w3">
    <div class="note f-w3" v-if="sex == 2">
      日付や場所、集合方法などを話しましょう。<br />
      返信が早い人の方がマッチ率が高いです！
    </div>
    <div class="note f-w3" v-else>
      <p class="note-top">日付や場所、集合方法について話しましょう。</p>
      <div class="note-center">
        <div class="note-center-item">
          <img
            :src="require('@/assets/image/tick.svg')"
            class="button__icon"
            alt="like"
          />
          <span>お相手のラウンド代を負担してあげられる方</span>
        </div>
        <div class="note-center-item">
          <img
            :src="require('@/assets/image/tick.svg')"
            class="button__icon"
            alt="like"
          />
          <span>車でピックアップにいくことができる方</span>
        </div>
      </div>
      <p class="note-bottom">
        上記が可能な方はあらかじめ伝えてあげると返信率が上がります。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContinueDatePopup",
  // methods: {
  //   addMessageCalendar() {
  //     this.$emit("identify");
  //   }
  // }
  props: {
    sex: {
      type: Number,
      default: 1
    }
  }
};
</script>

<style scoped lang="scss">
.create-order {
  .note {
    font-size: 16px;
    line-height: 22px;
    margin-top: 29.49px;
    color: #000000;
    .not-top {
      margin: 0 -2px 16px;
    }
    .note-center {
      margin: auto;
      display: inline-block;
      .note-center-item {
        text-align: left;
      }
    }
    .note-bottom {
      margin: 19px -2px 0;
    }
  }
}
@media only screen and (min-width: 1079px) {
  .create-order {
    width: 480px;
    .note {
      font-size: 18px;
      line-height: 24.5px;
      margin-top: 34.87px;
      color: #000000;
    }
  }
}

@media only screen and (max-width: 1079px) {
  .create-order {
    .note {
      font-size: 14px;
    }
  }
}
</style>
