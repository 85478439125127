<template>
  <transition name="show">
    <div
      v-if="showModal"
      class="modal d-flex align-items-center justify-content-center"
    >
      <div
        class="event-confirm d-flex flex-column align-items-center justify-content-center"
      >
        <button class="btn btn-close" @click="closeComponent()">
          <img :src="require('@/assets/image/close.svg')" alt="close popup" />
        </button>
        <p
          class="f-w6 fs-16 text-center mt15"
          v-if="!contactConfirm && !contact && !popupReason"
        >
          合流は出来ましたか？
        </p>
        <p class="f-w6 fs-16 text-center mt15" v-if="contactConfirm">
          連絡はとれていますか？
        </p>
        <div class="f-w6 fs-16 text-center flex-column mt15" v-if="contact">
          <img
            :src="require('@/assets/image/message/phone_pink.svg')"
            class="mb-3"
            alt="close popup"
          />
          <div class="font-weight-normal mb-2">お相手に連絡をし、</div>
          <div class="font-weight-normal">もうしばらくお待ちください</div>
        </div>

        <div v-if="popupReason" :style="{ width: '100%' }">
          <p class="f-w6 fs-16 text-center mb-4 mt15">
            予約をキャンセルする理由
          </p>
          <select
            class="f-w6 fs-14 custom-control sel-custom mb-4"
            v-model="reasonSelected"
          >
            <option
              v-for="(reason, index) of reasons"
              :value="reason"
              :key="index"
              class="f-w6 fs-14 font-weight-normal"
            >
              {{ reason }}
            </option>
          </select>
          <div class="d-flex justify-content-between">
            <button class="btn-event" @click="sendReason">
              確定する
            </button>
            <button class="btn-cancel-event" @click="closeComponent">
              キャンセル
            </button>
          </div>
        </div>

        <div
          v-if="!popupReason && !contactConfirm && !contact"
          class="d-flex justify-content-center"
        >
          <button class="btn-event" @click="closeComponent">
            はい
          </button>
          <button class="btn-cancel-event" @click="contactConfirm = true">
            いいえ
          </button>
        </div>

        <div
          v-if="!popupReason && contactConfirm"
          class="d-flex justify-content-center"
        >
          <button class="btn-event" @click="showMessContact">
            はい
          </button>
          <button class="btn-cancel-event" @click="showPopupReason">
            いいえ
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "MessageDelayConfirm",
  props: {
    showModal: {
      type: Boolean
    }
  },
  data() {
    return {
      contactConfirm: false,
      contact: false,
      popupReason: false,
      reasonSelected: "待ち合わせに5分遅れてる",
      reasons: [
        "待ち合わせに5分遅れてる",
        "待ち合わせに15分遅れてる",
        "待ち合わせに30分遅れてる",
        "待ち合わせに1時間遅れてる"
      ]
    };
  },
  methods: {
    changeShowModal() {
      this.$emit("change-show-confirm", false);
    },
    showMessContact() {
      this.contact = true;
      this.contactConfirm = false;
    },
    showPopupReason() {
      this.contactConfirm = false;
      this.popupReason = true;
    },
    closeComponent() {
      this.contactConfirm = false;
      this.contact = false;
      this.popupReason = false;
      this.reasonSelected = "待ち合わせに5分遅れてる";
      this.changeShowModal();
    },
    sendReason() {
      this.$emit("send-reason", this.reasonSelected);
      this.closeComponent();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}
.event-confirm {
  width: 100%;
  max-width: 375px;
  background-color: #fff;
  padding: 30px 19px;
  position: relative;

  .btn-close {
    position: absolute;
    top: 15px;
    right: 19px;
    img {
      width: 14px;
      display: block;
    }
  }

  > img {
    width: 105px;
    margin-bottom: 25px;
  }

  p {
    margin-bottom: 30px;
    color: #0b2044;
  }

  .btn-confirm {
    height: 45px;
    border-radius: 5px;
    background-color: #464d77;
    color: #fff;
    width: 100%;
  }
}
.show {
  &-enter,
  &-leave-to {
    opacity: 0;
    visibility: hidden;
  }

  &-enter-active,
  &-leave-active {
    transition: ease 0.4s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    visibility: visible;
  }
}
.btn-event {
  width: 155px;
  height: 45px;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 10px;
  color: #ffffff;
  background-color: #464d77;
}
.btn-cancel-event {
  color: #000000;
  background-color: #e4e4e4;
  width: 155px;
  height: 45px;
  font-size: 16px;
  border-radius: 5px;
}
.sel-custom {
  height: calc(1.5em + 0.75rem + 5px);
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background: white;
  font-weight: 400 !important;
}
.mt15 {
  margin-top: 15px;
}
</style>
