<template>
  <div class="align-self-end box-cover">
    <div
      @click="purchaseGift"
      class="
        shop-box
        d-flex
        flex-column
        align-items-center
        justify-content-center
      "
    >
      <img
        :src="data.image_url || require('@/assets/image/gift.svg')"
        @load="$emit('load-success')"
        alt="Gift"
      />
      <!-- <span class="sp-name f-w3">{{ data.name }}</span> -->
      <span class="sp-point f-w6">
        {{
          data.type === 1
            ? "フリー"
            : numberWithCommas(parseInt(data.points_spent)) + "P"
        }}
      </span>
    </div>
    <ModalLayout ref="modal-buy-point">
      <p class="title f-w6">ポイントが不足しています。</p>
      <button class="modal__btn" @click="nextBuyPoint()">
        ポイント購入する
      </button>
    </ModalLayout>
  </div>
</template>

<script>
import { numberWithCommas } from "@/utils/convert";
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Object
    }
  },
  components: {},
  name: "GiftBox",
  data() {
    return {
      openConfirm: false
    };
  },
  methods: {
    numberWithCommas,
    purchaseGift() {
      if (Number(this.data.points_spent) > this.user.pg_points_balance) {
        this.$refs["modal-buy-point"].openModal();
        return;
      }
      this.$emit("change-confirm", this.data);
    },
    nextBuyPoint() {
      localStorage.setItem("message_id", this.$route.params.id);
      this.$router.push({ name: "BuyPoint" });
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/men/giftBox.scss";
.title {
  font-size: 18px;
  margin-top: 20px;
}
.modal__btn {
  height: 45px;
  border-radius: 5px;
  background-color: #464d77;
  color: #ffffff;
}
</style>
