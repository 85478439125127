var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{staticClass:"suggest",class:_vm.show ? 'show-stk' : 'hide-stk'},[_c('div',{staticClass:"suggest__content",style:((_vm.authUser.sex === 2 && _vm.authUser.identity_status != 3) ||
      (_vm.authUser.sex === 1 && !_vm.monthlyPlanStatus)
        ? { padding: '0px' }
        : { padding: '0px 0 0 0' })},[_c('div',{class:(_vm.authUser.sex === 2 && _vm.authUser.identity_status != 3) ||
        (_vm.authUser.sex === 1 && !_vm.monthlyPlanStatus)
          ? 'suggest__list d-flex flex-wrap'
          : 'suggest__list d-flex flex-wrap not-show',attrs:{"id":(_vm.authUser.sex === 2 && _vm.authUser.identity_status != 3) ||
        (_vm.authUser.sex === 1 && !_vm.monthlyPlanStatus)
          ? ''
          : 'suggesListMsg'}},_vm._l((_vm.suggestList),function(item,key){return _c('div',{key:key,staticClass:"suggest__item fs-14 f-w3 msg-sg",class:{ 'suggest__item--lg': item === '未定' },on:{"click":function($event){return _vm.pickSuggestImage(item.image, item.content, item.type)}}},[_c('div',{staticClass:"msg-item"},[_c('div',{staticClass:"icon-msg"},[_c('img',{staticClass:"icon-suggest-msg",class:item.class,attrs:{"src":item.image,"alt":item.content}})])])])}),0)]),_c('MessageStickerConfirm',{attrs:{"showModal":_vm.openConfirm},on:{"change-show-confirm":function($event){_vm.openConfirm = false},"send-sticker":function($event){return _vm.sendSticker()}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }