<template>
  <div class="gift-cover">
    <div
      @click="tip"
      class="gift-box d-flex flex-column align-items-center justify-content-center"
    >
      <img
        :src="data.image_url || require('@/assets/image/gift.svg')"
        @load="$emit('load-success')"
        alt="Gift"
      />
      <!-- <span class="sp-name f-w3">{{ data.name }}</span> -->
      <span class="sp-point f-w6">{{
        data.type === GIFT_TYPE.NOT_FREE
          ? `${numberWithCommas(parseInt(data.points_spent))}P`
          : "フリー"
      }}</span>
    </div>
    <MessageGiftBoxConfirm
      :showModal="openConfirm"
      :imageUrl="data.image_url"
      @change-show-confirm="openConfirm = false"
      @purchase-gift="purchaseGift"
    />
    <ModalLayout ref="modal-buy-point">
      <p class="title f-w6">ポイントが不足しています。</p>
      <button class="modal__btn" @click="nextBuyPoint()">
        ポイント購入する
      </button>
    </ModalLayout>
  </div>
</template>

<script>
import MessageGiftBoxConfirm from "./MessageGiftBoxConfirm.vue";
import { numberWithCommas } from "@/utils/convert";
import { mapGetters } from "vuex";
import { GIFT_TYPE } from "@/utils/const";
export default {
  props: {
    data: {
      type: Object
    },
    roomId: {
      type: String
    },
    toUserId: {
      type: String
    }
  },
  components: {
    MessageGiftBoxConfirm
  },
  name: "MessageGiftBox",
  data() {
    return {
      GIFT_TYPE,
      openConfirm: false
    };
  },
  methods: {
    numberWithCommas,
    nextBuyPoint() {
      localStorage.setItem("message_id", this.$route.params.id);
      this.$router.push({ name: "BuyPoint" });
    },
    purchaseGift() {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("gift/purchaseGift", {
          user_id: this.user.user_id,
          gift_id: this.data.gift_id
        })
        .then(response => {
          this.tipping(response?.data?.user_gift_id);
        })
        .catch(() => {
          this.openConfirm = false;
          this.$root.$refs.loading.finish();
        });
      // this.$store
      //   .dispatch("gift/purchaseGift", {
      //     user_id: this.user.user_id,
      //     gift_id: this.giftId
      //   })
      //   .then(response => {
      //     this.openConfirm = false;
      //     this.tipping(response?.data?.user_gift_id);
      //   })
      //   .catch(() => {
      //     this.openConfirm = false;
      //     this.$root.$refs.loading.finish();
      //   });
    },
    tip() {
      if (
        Number(this.data.points_spent) > Number(this.user.pg_points_balance)
      ) {
        this.openConfirm = false;
        this.$refs["modal-buy-point"].openModal();
      } else {
        this.openConfirm = true;
      }
    },
    tipping(userGiftId) {
      this.$store
        .dispatch("gift/tip", {
          user_id: this.user.user_id,
          user_gift_id: userGiftId,
          to_user_id: this.toUserId,
          room_id: this.roomId
        })
        .then(() => {
          this.$root.$refs.loading.finish();
          this.$emit("close-popup");
          this.$emit("scroll-bottom");
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily.scss";
.gift-cover {
  // width: calc(100% / 3);
  margin-right: 21px;
}
.gift-box {
  width: 76px;
  // height: 80px;
  // margin: 0 20px 15px;
  img {
    width: 76px;
    margin-bottom: 10px;
  }
  .sp-name {
    font-size: 10px;
    color: #000000;
  }
  .sp-point {
    font-size: 12px;
    color: #000000;
  }
}
@media screen and (min-width: 1200px) {
  .gift-cover {
    // width: calc(100% / 3);
    margin-right: 30px;
  }
  .gift-box {
    width: 110px;
    // height: 115px;
    img {
      width: 110px;
    }
  }
}
.title {
  font-size: 18px;
  margin-top: 20px;
}
.modal__btn {
  height: 45px;
  border-radius: 5px;
  background-color: #464d77;
  color: #ffffff;
}
</style>
