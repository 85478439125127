<template>
  <transition name="show">
    <div
      v-if="showModal"
      class="modal d-flex align-items-center justify-content-center"
    >
      <div
        class="event-confirm d-flex flex-column align-items-center justify-content-center"
      >
        <button class="btn btn-close" @click="changeShowModal()">
          <img :src="require('@/assets/image/close.svg')" alt="close popup" />
        </button>
        <p class="f-w6 fs-16 text-center" v-if="status === 0">
          お相手と合流しましたか？
        </p>
        <p class="f-w6 fs-16 text-center" v-if="status === 1">
          お相手と解散しましたか？
        </p>

        <div class="d-flex justify-content-center">
          <button @click="$emit('confirm-event')" class="btn-event">
            はい
          </button>
          <button
            @click="$emit('change-show-confirm')"
            class="btn-cancel-event"
          >
            いいえ
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "MessageEventConfirm",
  props: {
    showModal: {
      type: Boolean
    },
    status: {
      type: Number
    }
  },
  methods: {
    changeShowModal() {
      this.$emit("change-show-confirm", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}
.event-confirm {
  width: 100%;
  max-width: 375px;
  background-color: #fff;
  padding: 30px 19px;
  position: relative;

  .btn-close {
    position: absolute;
    top: 15px;
    right: 19px;
    img {
      width: 14px;
      display: block;
    }
  }

  > img {
    width: 105px;
    margin-bottom: 25px;
  }

  p {
    margin-bottom: 30px;
    color: #0b2044;
  }

  .btn-confirm {
    height: 45px;
    border-radius: 5px;
    background-color: #464d77;
    color: #fff;
    width: 100%;
  }
}
.show {
  &-enter,
  &-leave-to {
    opacity: 0;
    visibility: hidden;
  }

  &-enter-active,
  &-leave-active {
    transition: ease 0.4s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    visibility: visible;
  }
}
.btn-event {
  width: 155px;
  height: 45px;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 10px;
  color: #ffffff;
  background-color: #464d77;
}
.btn-cancel-event {
  color: #000000;
  background-color: #e4e4e4;
  width: 155px;
  height: 45px;
  font-size: 16px;
  border-radius: 5px;
}
</style>
