<template>
  <!-- <transition name="fadeScale"> -->
  <div v-if="show" class="suggest" :class="show ? 'show-stk' : 'hide-stk'">
    <div
      class="suggest__content"
      :style="
        (authUser.sex === 2 && authUser.identity_status != 3) ||
        (authUser.sex === 1 && !monthlyPlanStatus)
          ? { padding: '0px' }
          : { padding: '0px 0 0 0' }
      "
    >
      <!--      <div>-->
      <!--        <button-->
      <!--          class="btn btn-close"-->
      <!--          id="btn-close"-->
      <!--          v-if="-->
      <!--            (authUser.sex === 2 && authUser.identity_status != 3) ||-->
      <!--              (authUser.sex === 1 && !monthlyPlanStatus)-->
      <!--          "-->
      <!--          @click="close()"-->
      <!--        >-->
      <!--          <img :src="require('@/assets/image/close.svg')" alt="close suggest" />-->
      <!--          &lt;!&ndash;          <a href="#" title="End" class="arrow-up up"></a>&ndash;&gt;-->
      <!--        </button>-->
      <!--      </div>-->
      <!--      <div-->
      <!--        class="suggest-title f-w6"-->
      <!--        v-if="-->
      <!--          (authUser.sex === 2 && authUser.identity_status != 3) ||-->
      <!--            (authUser.sex === 1 && !monthlyPlanStatus)-->
      <!--        "-->
      <!--      >-->
      <!--        <p>スタンプを送ってみよう!!</p>-->
      <!--      </div>-->
      <div
        :class="
          (authUser.sex === 2 && authUser.identity_status != 3) ||
          (authUser.sex === 1 && !monthlyPlanStatus)
            ? 'suggest__list d-flex flex-wrap'
            : 'suggest__list d-flex flex-wrap not-show'
        "
        :id="
          (authUser.sex === 2 && authUser.identity_status != 3) ||
          (authUser.sex === 1 && !monthlyPlanStatus)
            ? ''
            : 'suggesListMsg'
        "
      >
        <div
          v-for="(item, key) in suggestList"
          :key="key"
          class="suggest__item fs-14 f-w3 msg-sg"
          @click="pickSuggestImage(item.image, item.content, item.type)"
          :class="{ 'suggest__item--lg': item === '未定' }"
        >
          <!--          <div v-if="item === ''">-->
          <!--            <span v-if="authUser.sex === 1">-->
          <!--              Superオファーしたのでよかったら応募待ってます！-->
          <!--            </span>-->
          <!--            <span v-if="authUser.sex === 2">-->
          <!--              ゴルフしたいのでよかったらオファー待ってます♪-->
          <!--            </span>-->
          <!--          </div>-->
          <div class="msg-item">
            <div class="icon-msg">
              <img
                :src="item.image"
                :alt="item.content"
                :class="item.class"
                class="icon-suggest-msg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <MessageStickerConfirm
      :showModal="openConfirm"
      @change-show-confirm="openConfirm = false"
      @send-sticker="sendSticker()"
    />
  </div>
  <!-- </transition> -->
</template>

<script>
import { mapGetters } from "vuex";
import MessageStickerConfirm from "../Men/MesageStickerConfirm";

export default {
  name: "MessageSuggest",
  components: {
    MessageStickerConfirm
  },
  data() {
    return {
      // suggestList: [
      //   "こんにちは♪",
      //   "よろしくお願いします♪",
      //   "一緒にラウンドしませんか？",
      //   ""
      // ]
      suggestListQuestion_1: [
        {
          content: "こんにちは♪一緒にラウンド行ってみたいです。",
          image: require("@/assets/image/message/q_hello.svg"),
          type: 0,
          class: "item_1"
        },
        {
          content: "こんにちは♪良かったら打ちっ放し行きませんか？",
          image: require("@/assets/image/message/q_m_hello.svg"),
          type: 0,
          class: "item_2"
        }
      ],
      suggestListQuestion_2: [
        {
          content: "こんにちは♪一緒にラウンド行ってみたいです。",
          image: require("@/assets/image/message/q_hello.svg"),
          type: 0,
          class: "item_1"
        },
        {
          content: "こんにちは♪良かったら打ちっ放し行きませんか？",
          image: require("@/assets/image/message/q_w_hello.svg"),
          type: 0,
          class: "item_2"
        }
      ],
      suggestListReply_w1: [
        {
          content: "行きましょう♪",
          image: require("@/assets/image/message/a_w_reply_1.svg"),
          type: 1,
          class: "item_1"
        },
        {
          content: "まずは打ちっぱなしに行きませんか？",
          image: require("@/assets/image/message/a_w_reply_2.svg"),
          type: 1,
          class: "item_2"
        }
      ],
      suggestListReply_w2: [
        {
          content: "行きましょう♪",
          image: require("@/assets/image/message/a_w_reply_1.svg"),
          type: 1,
          class: "item_1"
        },
        {
          content: "ラウンドにしませんか？",
          image: require("@/assets/image/message/a_reply.svg"),
          type: 1,
          class: "item_2"
        }
      ],
      suggestListReply_m1: [
        {
          content: "行きましょう♪",
          image: require("@/assets/image/message/a_m_reply_1.svg"),
          type: 1,
          class: "item_1"
        },
        {
          content: "まずは打ちっぱなしに行きませんか？",
          image: require("@/assets/image/message/a_m_reply_2.svg"),
          type: 1,
          class: "item_2"
        }
      ],
      suggestListReply_m2: [
        {
          content: "行きましょう♪",
          image: require("@/assets/image/message/a_m_reply_1.svg"),
          type: 1,
          class: "item_1"
        },
        {
          content: "ラウンドにしませんか？",
          image: require("@/assets/image/message/a_reply.svg"),
          type: 1,
          class: "item_2"
        }
      ],
      suggestList: [],
      openConfirm: false
    };
  },
  props: {
    show: {
      type: Boolean,
      default: true
    },
    lastMsg: {
      type: Array
    },
    monthlyPlanStatus: {
      type: Object
    }
  },

  watch: {
    lastMsg(val) {
      if (val.length > 0) {
        let user = val[0];
        if (
          (this.authUser.sex === 2 && this.authUser.identity_status != 3) ||
          (this.authUser.sex === 1 && !this.monthlyPlanStatus)
        ) {
          if (user.message != "") {
            // if (this.authUser.sex === 1) {
            //   this.suggestList = this.suggestListReply_m1;
            // } else {
            //   this.suggestList = this.suggestListReply_w1;
            // }
            if (this.authUser.sex === 1) {
              this.suggestList = this.suggestListQuestion_1;
            } else {
              this.suggestList = this.suggestListQuestion_2;
            }
          } else {
            if (
              user.suggestText ==
                "こんにちは♪一緒にラウンド行ってみたいです。" &&
              this.authUser.user_id != user.user.id
            ) {
              if (this.authUser.sex === 1) {
                this.suggestList = this.suggestListReply_m1;
              } else {
                this.suggestList = this.suggestListReply_w1;
              }
            } else if (
              user.suggestText ==
                "こんにちは♪良かったら打ちっ放し行きませんか？" &&
              this.authUser.user_id != user.user.id
            ) {
              if (this.authUser.sex === 1) {
                this.suggestList = this.suggestListReply_m2;
              } else {
                this.suggestList = this.suggestListReply_w2;
              }
            } else {
              if (this.authUser.sex === 1) {
                this.suggestList = this.suggestListQuestion_1;
              } else {
                this.suggestList = this.suggestListQuestion_2;
              }
            }
          }
        } else {
          if (
            user.suggestText == "こんにちは♪一緒にラウンド行ってみたいです。" &&
            this.authUser.user_id != user.user.id
          ) {
            if (this.authUser.sex === 1) {
              this.suggestList = this.suggestListReply_m1;
            } else {
              this.suggestList = this.suggestListReply_w1;
            }
          } else if (
            user.suggestText ==
              "こんにちは♪良かったら打ちっ放し行きませんか？" &&
            this.authUser.user_id != user.user.id
          ) {
            if (this.authUser.sex === 1) {
              this.suggestList = this.suggestListReply_m2;
            } else {
              this.suggestList = this.suggestListReply_w2;
            }
          } else {
            if (this.authUser.sex === 1) {
              this.suggestList = this.suggestListQuestion_1;
            } else {
              this.suggestList = this.suggestListQuestion_2;
            }
          }
        }
      } else {
        if (this.authUser.sex === 1) {
          this.suggestList = this.suggestListQuestion_1;
        } else {
          this.suggestList = this.suggestListQuestion_2;
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      authUser: "auth/user"
    })
  },
  methods: {
    pickMessage(msg) {
      if (msg === "") {
        msg =
          this.authUser.sex === 2
            ? "ゴルフしたいのでよかったらオファー待ってます♪"
            : "Superオファーしたのでよかったら応募待ってます！";
      } else {
        msg = msg
          .replace("<b>", "")
          .replace("</b>", "")
          .replace("<br>", "");
      }
      this.$emit("pick-message", msg);
    },
    close() {
      // this.show = !this.show;
      this.$emit("close-suggest");
    },
    pickSuggestImage(imagePath, content, type) {
      this.openConfirm = true;
      let params = {
        path: imagePath,
        content: content,
        type: type
      };
      localStorage.setItem("sticker", JSON.stringify(params));
    },

    sendSticker() {
      this.openConfirm = false;
      let params = localStorage.getItem("sticker");
      params = JSON.parse(params);
      this.$emit("pick-suggest-image", params);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixin.scss";
.suggest {
  padding-top: 18px 19px;
  background-color: #ebebeb;
  transform-origin: bottom;

  .btn-close {
    @include borderRadius(22px, 22px, 50%);
    box-shadow: 0 3px 6px #00000029;
    position: absolute;
    top: 5px;
    right: 8px;
    padding: 0;
    background-color: #fff;
    @include flexCenter();
    img {
      display: block;
      width: 10px;
    }

    .arrow {
      display: inline-block;
      width: 9px;
      height: 9px;
      background: transparent;
      text-indent: -9999px;
      border-top: 2px solid #bfbfbf;
      border-left: 2px solid #bfbfbf;
      transition: all 250ms ease-in-out;
      text-decoration: none;
      color: transparent;
      margin-bottom: 3px;
    }

    .arrow:hover {
      border-color: gray;
      border-width: 3px;
    }

    .arrow-up {
      display: inline-block;
      width: 9px;
      height: 9px;
      background: transparent;
      text-indent: -9999px;
      border-top: 2px solid #bfbfbf;
      border-left: 2px solid #bfbfbf;
      transition: all 250ms ease-in-out;
      text-decoration: none;
      color: transparent;
      margin-top: 2px;
    }

    .arrow-up:hover {
      border-color: gray;
      border-width: 3px;
    }

    //.arrow:before {
    //  display: block;
    //  height: 200%;
    //  width: 200%;
    //  margin-left: -50%;
    //  margin-top: -50%;
    //  content: "";
    //  transform: rotate(45deg);
    //}

    .arrow.down {
      transform: rotate(-135deg);
      right: 175px;
    }

    .arrow-up.up {
      transform: rotate(45deg);
      left: 175px;
    }
  }

  &__content {
    position: relative;
    // border-radius: 10px 10px 0 0;
    // background-color: #EBEBEB;
    padding: 10px;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   top: 3px;
    //   height: 4px;
    //   width: 29px;
    //   border-radius: 2px;
    //   background-color: #e2e2e2;
    // }
  }

  &__list {
    border-radius: 5px;
    //margin-top: -10px;
    margin-left: 8px;
  }

  table {
    //border-collapse: separate;
    //border-spacing: 0;
    //width: 100%;
    //
    //td {
    //  background-color: #fff;
    //  height: 40px;
    //  border-right: 1px solid #d1d1d1;
    //  border-bottom: 1px solid #d1d1d1;
    //  color: #000000;
    //  &:first-child {
    //    border-left: 1px solid #d1d1d1;
    //  }
    //}
    //tr {
    //  &:first-child {
    //    td {
    //      width: 50%;
    //      border-top: 1px solid #fff;
    //      &:first-child {
    //        border-top-left-radius: 5px;
    //      }
    //    }
    //  }
    //
    //  &:last-child {
    //    td {
    //      border-radius: 0 0 5px 5px;
    //    }
    //  }
    //}
  }

  &__item {
    display: contents;
    //@include flexCenter();
    //height: 40px;
    //border-right: 1px solid #fff;
    //border-bottom: 1px solid #fff;
    //border-left: 1px solid #fff;
    width: 100%;

    //&:nth-child(1),
    //&:nth-child(2) {
    //  //width: 50%;
    //  border-top: 1px solid #fff;
    //}

    //&:nth-child(2) {
    //  //border-left: 0;
    //}
    //
    //&:first-child {
    //  margin-bottom: 10px;
    //  background-color: #fff;
    //}
    //
    //&:last-child {
    //  background-color: #fff;
    //}
    .icon-msg {
      img {
        width: 225px;
        pointer-events: none;
      }
    }
  }
}

.fadeScale {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: scaleY(0);
  }

  &-enter-active,
  &-leave-active {
    transform-origin: bottom;
    // transition: ease 0.5s;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
    transform: scaleY(1);
  }
}

@media screen and (max-width: 768px) {
  .suggest__item--lg {
    padding: 5px 10px;
    // height: 50px;
  }

  .icon-msg {
    img {
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 480px) {
  .icon-msg {
    img {
      width: 240px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 430px) {
  .icon-msg {
    img {
      width: 215px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 428px) {
  .icon-msg {
    img {
      width: 214px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 414px) {
  .icon-msg {
    img {
      width: 207px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 412px) {
  .icon-msg {
    img {
      width: 205px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 393px) {
  .icon-msg {
    img {
      width: 196px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 390px) {
  .icon-msg {
    img {
      width: 195px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 384px) {
  .icon-msg {
    img {
      width: 192px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 375px) {
  .icon-msg {
    img {
      width: 187px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 360px) {
  .icon-msg {
    img {
      width: 180px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 320px) {
  .icon-msg {
    img {
      width: 160px !important;
      pointer-events: none;
    }
  }
}

@media screen and (max-width: 285px) {
  .icon-msg {
    img {
      width: 140px !important;
      pointer-events: none;
    }
  }
}

.suggest-title {
  //margin-top: 35px;
  font-size: 18px;
  margin-left: 10px;
  font-weight: 600 !important;
  text-align: left;

  p {
    margin-bottom: 0;
  }
}

.suggest__list {
  margin-left: 0 !important;
}

.suggest__item {
  //justify-content: left;
  //padding-left: 15px;
  //text-align: left;
  //padding-top: 7px;
  //padding-bottom: 7px;
  //height: 55px;
  //border-radius: 10px;
}

.msg-item {
  .msg-content {
    margin-left: 10px;
  }

  .icon-msg {
    cursor: pointer;
  }

  .icon-msg {
    img:hover {
      transform: scale(1.005);
    }
  }
}
</style>
