<template>
  <!-- <transition name="slideUp"> -->
  <div v-if="showStatus" class="message-gift position-relative">
    <div
      class="message-gift__header d-flex align-items-center justify-content-between"
    >
      <button class="btn btn-close" @click="changeShowGift()">
        <img :src="require('@/assets/image/left-arrow.svg')" alt="back" />
      </button>
      <p class="mb-0 f-w6 fs-14">ギフト一覧</p>
      <button @click="showShopGift()" class="btn btn-shop pd-0">
        <img :src="require('@/assets/image/shop.svg')" alt="Shop" />
      </button>
    </div>
    <div
      id="mesage-gift-body"
      class="message-gift__body d-flex justify-content-left align-items-center"
    >
      <template v-if="giftAvailables.length > 4">
        <div
          class="position-absolute icon-slider icon-slider-next"
          @click="next()"
          v-if="isShowNext"
        >
          <img src="@/assets/image/message/next.svg" alt="" />
        </div>
        <div
          v-if="isShowPre"
          class="position-absolute icon-slider icon-slider-pre"
          @click="pre()"
        >
          <img src="@/assets/image/message/pre.svg" alt="" />
        </div>
      </template>
      <template v-for="(gift, index) in giftAvailables">
        <MessageGiftBox
          :key="index"
          :data="gift"
          :roomId="roomId"
          :toUserId="toUserId"
          @load-success="totalLoad++"
          @close-popup="$emit('show-gift')"
          @scroll-bottom="$emit('scroll-bottom')"
        />
      </template>
    </div>
    <!-- <MessageGiftShop
        :showStatus="showGiftShop"
        :toUserId="toUserId"
        :roomId="roomId"
        @show-gift-shop="showGiftShop = !showGiftShop"
        @close-popup="$emit('show-gift')"
        @scroll-bottom="$emit('scroll-bottom')"
      /> -->
  </div>
  <!-- </transition> -->
</template>

<script>
import MessageGiftBox from "./MessageGiftBox.vue";
// import MessageGiftShop from "../Men/MessageGiftShop.vue";
import { mapGetters } from "vuex";
import { STATUS_GIFT_USER } from "@/utils/const";
export default {
  components: {
    MessageGiftBox
    // MessageGiftShop
  },
  data() {
    return {
      STATUS_GIFT_USER,
      showGiftShop: false,
      totalLoad: 0,
      showSuggestGift: true,
      isShowNext: true,
      isShowPre: false
    };
  },
  name: "MessageGift",
  props: {
    showStatus: {
      type: Boolean
    },
    roomId: {
      type: String
    },
    toUserId: {
      type: String
    }
  },
  methods: {
    changeShowGift() {
      this.$emit("show-gift", false);
    },
    showShopGift() {
      this.$emit("show-gift-shop", "popup");
    },
    pre() {
      let body = document.getElementById("mesage-gift-body");
      body.scrollLeft -= 150;
      this.isShowNext = true;
      if (body.scrollLeft - 150 <= 0) {
        this.isShowPre = false;
      }
    },
    next() {
      let body = document.getElementById("mesage-gift-body");
      body.scrollLeft += 150;
      this.isShowPre = true;
      if (body.scrollLeft + body.clientWidth + 150 >= body.scrollWidth) {
        this.isShowNext = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      gifts: "gift/gifts",
      giftAvailables: "gift/giftAvailables"
    })
  },
  watch: {
    totalLoad(val) {
      if (val === this.giftAvailables.length) {
        this.$emit("updated-success");
        this.totalLoad = 0;
      }
    }
  },
  created() {
    this.$root.$refs.loading.start();
    this.$store
      .dispatch("gift/getGiftAvailables")
      .then(() => {
        this.$root.$refs.loading.finish();
      })
      .catch(() => {
        this.$root.$refs.loading.finish();
      });
  }
};
</script>

<style lang="scss" scoped>
.message-gift {
  border-radius: 20px 20px 0 0;
  box-shadow: 0 -2px 15px #00000029;
  // position: fixed;
  // position: absolute;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  z-index: 2;
  background-color: #ffffff;

  &__header {
    height: 50px;
    border-bottom: 2px solid #e1e1e1;
    padding: 0 20px;

    p {
      color: #000000;
    }
    .btn {
      width: 20px;
      padding: 0;
      img {
        display: block;
      }
      &.btn-close {
        img {
          width: 14px;
          opacity: 0.7;
        }
      }
      &.btn-shop {
        img {
          width: 19px;
          opacity: 0.7;
        }
      }
    }
  }

  &__body {
    overflow-x: scroll;
    padding: 20px 0;
    margin: 0 15px;
    height: 159px;
  }
}

@media screen and (min-width: 1200px) {
  .message-gift {
    &__body {
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      scroll-behavior: smooth;
      padding: 25px 0;
      margin: 0 35px;
      height: 234px;

      .icon-slider-next {
        top: 50%;
        transform: translateY(calc(-50% + 25px));
        right: 0;
      }
      .icon-slider-pre {
        top: 50%;
        transform: translateY(calc(-50% + 25px));
        left: 0;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .icon-slider {
    display: none;
  }
}

.slideUp {
  &-enter,
  &-leave-to {
    opacity: 0;
    visibility: hidden;
    // transform: scaleY(0);
    // transform: translateY(100%);
  }

  // &-enter-active,
  // &-leave-active {
  //   transition: ease 0.3s;
  //   transform-origin: bottom;
  // }

  &-enter-to {
    opacity: 1;
    visibility: visible;
    // transform: scaleY(1);
    // transform: translateY(0);
  }
}
</style>
