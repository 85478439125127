var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-modal",class:{ active: _vm.showModal }},[_c('transition',{attrs:{"name":"slide-down"}},[(_vm.showModal)?_c('div',{staticClass:"app-modal__dialog"},[_c('div',{staticClass:"event-confirm d-flex flex-column align-items-center justify-content-center p-4"},[_c('button',{staticClass:"btn btn-close",on:{"click":function($event){return _vm.changeShowModal()}}},[_c('img',{attrs:{"src":require('@/assets/image/close.svg'),"alt":"close popup"}})]),_c('div',{staticClass:"mt-2"},[_c('img',{attrs:{"src":require('@/assets/image/report-user.svg'),"alt":"time report"}})]),(_vm.castList.length > 1 && _vm.authUser.sex === 1)?_c('div',{staticClass:"mt-2"},[_c('b-form-group',{staticClass:"mb-0 form-check-report",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"f-w3 check-box",attrs:{"options":_vm.castList,"aria-describedby":ariaDescribedby,"name":"flavour-2a","stacked":"","button-variant":"red"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]}}],null,false,1978783275)})],1):_vm._e(),_c('div',{staticClass:"w-100 mt-2"},[_c('button',{staticClass:"btn-confirm",attrs:{"disabled":_vm.castList.length > 1 &&
                !(
                  _vm.selected.length -
                  (_vm.userReports.length ? _vm.userReports[0].length : 0)
                ) &&
                _vm.authUser.sex === 1},on:{"click":_vm.sendReport}},[_vm._v(" 運営に報告する ")])])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }